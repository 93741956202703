

/* card section */
.big-card {
  margin: auto;
  margin-bottom: 3.5%;
  width: 80%;
  height: 30vh;
  font-size: 24px;
  position: relative;
  border: 5px solid var(--primary-color);
  display: flex;
  border-radius: 15px;
  background-image: url("../../assets/images/Pet-Portal/Pet-Portal.png");
  background-size: 101% 350px;
  z-index: 1;
}

.big-card::after {
  content: "";
  width: 100%;
  height: 101%;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, .5);
  z-index: -1;
  transition: transform 350ms ease-out;
  transform-origin: left;

}

.big-card:hover::after {
  transform: scaleX(0);
  transform-origin: right;
}

.big-card div {
  width: 100%;
  position: absolute;
  justify-content: center;
  background: var(--secondary-color);
  border-radius: 10px 10px 0 0;
  color: #fff;
}

.Run-Buddy{
  background-image: url("../../assets/images/Run-Buddy/Run-Buddy.png");
}

.Pokémon-Questionaire {
  background-image: url("../../assets/images/Pokémon-Questionaire/Pokémon-Questionaire.png");
}

.Just-Tech {
  background-image: url("../../assets/images/Just-Tech/Just-Tech.png");
}

.Schedule {
  background-image: url("../../assets/images/Schedule/Schedule.png");
}


.sub-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  text-align: center;
  border: 5px solid var(--primary-color);
  margin: 10px;
  height: 20vh;
  color: var(--light);
  background-color: var(--secondary-color);
  border-radius: 15px;
  z-index: 1;
  background-position: center center;
  background-size: 100% 170px;
  background-repeat: no-repeat;
}

.card::after{
  content: "";
  position: absolute;
  border-radius: 15px;
  width: 101%;
  left: -2px;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: -1;
  transition: transform 350ms ease-out;
  transform-origin: left;
}

.card:hover::after {
  transform: scaleX(0);
  transform-origin:right;
}

.modalBackdrop {
  position: absolute;
	width: 100%;
	z-index: 3;
}

.modalContainer {
  color: var(--light);
  background: rgba(0, 0, 0, .85);
	border-radius: 5px;
  border: 5px solid var(--primary-color);
	margin: auto;
	padding: 15px;
	width: 60%;
}

.modalTitle {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

.port {
  text-align: center;
  align-items: center;
}

.projects {
  text-align: center;
  align-items: center;
}

.gif-img {
  width: 80%;
}

.contact-container {
  /* position: relative; */
  width: 59%;
  margin: auto;
  margin-top: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact {
  width: 100%;
  border: 2px solid var(--dark);
  color: var(--light);
  border-radius: 10px;
  text-align: start;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns:  repeat(2, 1fr);
}

.dark .contact {
  border-color: var(--light);
}

.contactForm {
  position: relative;
  text-align: start;
  background-color: var(--primary-color);
}

.contactForm::before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: var(--primary-color);
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
  z-index: -10;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  /* overflow: hidden; */
  position: relative;
}

.title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

label {
  /* display: none; */
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid black;
  background: none;
  padding: 0.6rem 1.2rem;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.sub-btn {
  padding: 0.6rem 1.3rem;
  background-color: var(--light);
  border: 2px solid #fafafa;
  font-size: .95rem;
  color: var(--primary-color);
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;

}

.sub-btn:hover {
  background-color: transparent;
  color: var(--light);
}

.contact-info {
  background-color: var(--dark);
  color: var(--light);
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info h1 {
  font-weight: 500;
  padding: 0 1.5rem;
  font-size: 1.5rem;
  line-height: 1;
}

.contact-info .title {
  color: #1abc9c;
}
.dark .contact-info {
  background-color: #FFFDD0;
  color: var(--dark);
}

.text {
  margin: 1.5rem 0 2rem 0;
}

@media screen and (min-width: 769px) and (max-width: 1500px) {
  .contact-container {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .contact-container {
    width: 90%;
  }
  .contact {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(.95);
  }

  .contactForm::before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  @media screen and (max-width: 576px) {
    form,
    .contact-info {
      padding: 1.7rem 1.6rem;
    }

  }
}
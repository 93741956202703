:root {
  --primary-color: #65a890;
  --secondary-color: #ee6252;
  --light: #fafafa;
  --mid: #305ea7;
  --dark: #08101d;
}

* {
  box-sizing: border-box;
}

.light {
  background-color: #f5f5f5;
  color: var(--dark);
}

.dark {
  background-color: var(--dark);
  color: var(--light);
}

.page {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}


html,
body {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: .1em;
  background: var(--dark);
  color: var(--light);
  font-family: 'Poiret One', cursive;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1.25;
}

.navActive {
  color: var(--secondary);
}

footer {
  font-size: 3em;
  margin-top: auto;
}

footer i {
  margin: 5%;
}

.resume {
  text-decoration: none;
}

.resume:hover {
  color: var(--light);
}

.resume-div {
  margin: 0 10%;
  text-align: start;
}

.resume-div a {
  color: #FFFDD0;
}

.dark .footerLink {
  color: var(--light);
  text-decoration: none;
}

.light .footerLink {
  color: var(--dark);
}

.self-img {
  border-radius: 50%;
  height: 400px;
  width: 300px;
}

.landing-intro {
  margin-top: 5%;
}

.landing-div {
  margin: 10% 0;
}

.landing-div h1 {
  margin-bottom: 5%;
}

.nav-link-btn {
  text-decoration: none;
  color: var(--light);
  background-color: var(--primary-color) !important;
  border-color: var(--dark) !important;
}

.nav-link-btn:hover {
  color: var(--dark);
}

.modal-btn {
  background-color: var(--primary-color) !important;
  border-color: var(--dark) !important;
}

.modal-btn:hover {
  color: var(--dark) !important;
}

.light .resume-div a {
  color: var(--primary-color);
}

.light .resume-div a:hover {
  color: rgb(219, 159, 79);
}